<template>
    <section class="lock">
        <el-button type="primary" size="small" @click="openDialog" v-if="setPassWordShow && iotTag === '0'">设置密码</el-button>
<!--      v-show="iotTag === '1'"-->
        <el-button type="primary" size="small" @click="openDialog1" >设置密码</el-button>
        <el-button type="primary" size="small" @click="dcLock">生成租户密码</el-button>
        <el-button type="danger" size="small" @click="lockClear">清空</el-button>
        <el-button type="primary" size="small" @click="look" v-if="iotTag === '3'">查看开锁记录</el-button>
        <el-button type="primary" size="small" @click="Unlock" v-if="iotTag === '1'">查看开锁记录</el-button>
        <el-button type="primary" size="small" @click="replace">更换门锁</el-button>
      <el-button type="primary" size="small" @click="operatingrecord">操作记录</el-button>
        <r-e-table ref="lockIdTableRef" :columns="tableColumnLockId" :dataRequest="getLocks" :query="formSearch" :height="750" row-key-id="uuid">
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span v-if="(row.state === 66 && row.type === 1)||(row.state === 66 && row.type === 2)" class="table-btn" @click="passDel(row)">删除</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <dialog-set-lock-temporary ref="dialogSetLockTemporary" :equipmentData="equipmentData"/>
        <dialog-set-lock-temporarys ref="dialogSetLockTemporarys" :equipmentData="equipmentData"/>
        <dialog-show-unlock-record ref="dialogShowUnlockRecord" :equipmentData="equipmentData"/>
        <!-- 新增查看开锁记录 -->
        <dialog-unlock-two-record  ref="dialogUnlockTwoRecord" :equipmentData="equipmentData"/>
        <dialogr-replace-lock ref="dialogrReplaceLock" :equipmentData="equipmentData"/>
<!--       操作记录-->
       <dialogoperatingrecord ref="operatingrecordref" :equipmentData="equipmentData"></dialogoperatingrecord>
    </section>
</template>

<script>
    import {getLocks, lockClear,dcLock,passDel,getMenu} from "@/api/door-management";
    import {MessageSuccess, MessageError, MessageInfo} from "@custom/message";
    import {tableColumnLockId} from "@/views/property-management/door-management/data";
    export default {
        name: "lock",
        props: {
            equipmentData: {
                type: Object,
                default: () => ({})
            }
        },
        data() {
            return {
                tableColumnLockId:tableColumnLockId(this),
                formSearch: {},
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                setPassWordShow: false,
                iotTag:0
            };
        },
        components: {
            dialogSetLockTemporary: () => import("./components/dialog-set-lock-temporary"),
            dialogSetLockTemporarys: () => import("./components/dialog-sets-lock-temporarys"),
            dialogShowUnlockRecord: () => import("./components/dialog-show-unlock-record"),
            dialogoperatingrecord: () => import("./components/dialog-operatingrecord"),
            dialogUnlockTwoRecord: () => import("./components/dialog-unlock-two-record .vue"),
            dialogrReplaceLock: () => import("./components/dialog-replace-lock"),
        },
        mounted() {

            console.log('equipmentData',this.equipmentData);
            console.log('equipmentData',this.equipmentData.iotTag);
            let {apartmentUuid: uuid,iotTag} = this.equipmentData;
            this.iotTag = iotTag
             getMenu(uuid).then(({info}) => {
                console.log('info--->',info);
                this.setPassWordShow = info;
            });
        },
        methods: {
            openDialog() {
                const {lockId} = this.equipmentData;
                if (lockId === null) {
                    MessageError('当前房源没有智能门锁无法发送');
                    return;
                }
                this.$refs["dialogSetLockTemporary"].openDialog(lockId);
            },
            // 张晓瑜新增iotTag = 1的下发离线密码 弹窗
            openDialog1(){
              console.log(this.equipmentData)
                const {lockId,apartmentUuid} = this.equipmentData;
                if (lockId === null) {
                    MessageError('当前房源没有智能门锁无法发送');
                    return;
                }
                this.$refs["dialogSetLockTemporarys"].openDialog(lockId,apartmentUuid);
            },
            dcLock() {
                const that = this;
                const {uuid, leasorPhone, lockId} = this.equipmentData;
                if (lockId === null) {
                    MessageError('当前房源没有智能门锁无法发送');
                    return;
                }
                if (leasorPhone === null) {
                    MessageError('当前房源没有租户无法发送');
                    return;
                }


                that.$confirm('是否删除历史密码?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {
                    const loadingOptions = this.loadingOptions;
                    const loading = this.$loading({...loadingOptions});
                    dcLock({uuid,phone:leasorPhone,isDelete: true}).then(res => {
                        that.$alert('生成租户密码成功', '密码提醒', {type:'success',confirmButtonText: '确定'});
                        MessageSuccess('生成租户密码成功');
                        this.handleSearchLocks();
                        loading.close();
                    }).catch(err => loading.close());
                }).catch(() => {
                    const loadingOptions = this.loadingOptions;
                    const loading = this.$loading({...loadingOptions});
                    dcLock({uuid,phone:leasorPhone,isDelete:false}).then(res => {
                        that.$alert('生成租户密码成功', '密码提醒', {type:'success',confirmButtonText: '确定'});
                        MessageSuccess('生成租户密码成功');
                        this.handleSearchLocks();
                        loading.close();
                    }).catch(err => loading.close());
                });
            },
            lockClear(){
                const {uuid, leasorPhone, lockId} = this.equipmentData;
                if (lockId === null) {
                    MessageInfo('当前房源没有智能门锁无需清空');
                    return;
                }
                const loadingOptions = this.loadingOptions;
                const loading = this.$loading({...loadingOptions});
                let paramData = {uuid, phone: leasorPhone};
                lockClear(paramData).then(res => {
                    MessageSuccess('清空门锁密码成功');
                    loading.close();
                }).catch(err => loading.close());
            },
            getLocks(params){
                params.uuid=this.equipmentData.uuid;
                return getLocks(params);
            },
            handleSearchLocks() {
                this.$refs["lockIdTableRef"].getTableData();
            },
            passDel(data){
                let {uuid} = data;
                this.$confirm('此操作改密码将永久不可用, 是否继续?', '提示', {
                    type: 'warning'
                }).then(() => {
                    const loadingOptions = this.loadingOptions;
                    const loading = this.$loading({...loadingOptions});
                    passDel(uuid).then(res => {
                        MessageSuccess('不可用租户密码成功');
                        setTimeout(() => {
                            loading.close();
                            this.handleSearchLocks();
                        }, 2000);
                    }).catch(err => loading.close());
                }).catch(() => {});
            },
            // 开锁记录
            look(){
                const {uuid,iotTag} = this.equipmentData;
                this.$refs["dialogShowUnlockRecord"].openDialog(uuid,iotTag);
            },
            // 2023/08/31 张晓瑜新增
            // 开锁记录
            Unlock(){
                const {uuid} = this.equipmentData;
                this.$refs["dialogUnlockTwoRecord"].openDialog(uuid);
            },

            replace(){
                this.$refs["dialogrReplaceLock"].openDialog();
            },
        //   2024-3-13-陈守亮-操作记录
          operatingrecord(){
            const {uuid} = this.equipmentData;
            this.$refs["operatingrecordref"].openDialog(uuid);
          }
        }
    }
</script>

<style scoped>

</style>
